@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&family=Lato:wght@200;300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none !important;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  height: auto !important;
}

body {
  font-family: "Lato", sans-serif !important;
  overflow: auto;
  -ms-overflow-style: none; /* IE */
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
th,
thead {
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
}

input[type="time"],
input[type="date"],
input[type="number"] {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  display: none;
}

